Zolpan.paintCalculator = Zolpan.paintCalculator || {};

Zolpan.form.paintCalculator = (function ($) {

    var vanillaModal = new VanillaModal.default();

    var $calculateSubmitButton = $("button[name='calculateSubmit']");

    var paintProjectNameModalId = '#PaintProjectNameModal';
    var $paintFormContainer = $("#PaintFormContainer");
    var $paintCalculatorForm = $("#FormPaintCalculator");
    var $paintCalculatorResult = $("#paintcalculator-results");
    var $paintProjectNameForm = $("#PaintProjectNameForm");

    function init() {
        listenPaintProjectFormSubmission();
    }

    function listenPaintProjectFormSubmission() {

        $paintCalculatorResult.find("button[name='saveSubmit']").on('click', function () {
            var openModal = vanillaModal.open(paintProjectNameModalId);

            $.when(openModal).done(function () {
                $paintProjectNameForm.on('submit', function (event) {
                    event.preventDefault();

                    // add a new value (projectName) without changing request Array for PHP handling
                    var data = $paintCalculatorForm.serializeArray();
                    data.push({name: 'projectName', value: $(this).find('#PaintProjectName').val()});
                    var params = new URL(document.location).searchParams;
                    if(params.get("projet")) {
                      data.push({name: 'projet', value: params.get("projet")});
                    }
                    $.ajax({
                        url: Routing.generate('zolpan.form.paint_project.submit'),
                        data: data,
                        type: 'POST',
                        statusCode: {
                            201: function (url) {
                                window.location.href = url
                            },
                            500: function (response) {
                                vanillaModal.close(paintProjectNameModalId);
                                Zolpan.flashbag.showErrorMessage(response.responseText);
                                $calculateSubmitButton.prop('disabled', true);
                            },
                            400: function (response) {
                                vanillaModal.close(paintProjectNameModalId);
                                $paintCalculatorForm.remove();
                                $paintFormContainer.html(response.responseText);
                            },
                            403: function (response) {
                                vanillaModal.close(paintProjectNameModalId);
                                Zolpan.flashbag.showErrorMessage(response.responseText);
                                $calculateSubmitButton.prop('disabled', true);
                            }
                        }
                    });
                });
            });
        });
    }

    $(document).ready(init);

})(jQuery);
