(function($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var DATABURGER_SEL = "[data-burgermenu]";
  var CONTAINER_SEL = ".MainNav";
  var CLOSE_SEL = ".MainNav-close";

  // Automatic menu selector
  var ITEM_SEL = "a.MainNav-item";
  var BIGITEM_SEL = ".MainNav-bigNav";
  var ACTIVE_ITEM_SEL = ".MainNav-activeItem"

  // Manual menu selector
  var MANUAL_MENU = ".ManualMenu";
  var MANUAL_MENU_ITEM_SEL = ".ManualMenu-MainNav-item";
  var MANUAL_MENU_BIGITEM_SEL = ".ManualMenu-MainNav-bigNav";
  var MANUAL_MENU_ACTIVE_ITEM_SEL = ".ManualMenu-MainNav-activeItem";
  var MANUAL_CLOSE_SEL = ".ManualMenu-MainNav-bigNavClose"

  // Close menu block on mobile
  var MOBILE_CLOSE_SEL = ".MainNav-activeItem-close"
  var MOBILE_BACK_SEL = ".MainNav-activeItem-arrow-back"
  
  // Close icon on desktop
  var DESKTOP_CLOSE_SEL = ".MainNav-bigNavClose"

  // Overlay used when menu is open
  var OVERLAY = "#overlay";

  /**
   * Init
   */
  function init() {
    var container = $(CONTAINER_SEL);
    if (container.length === 0) {
      return;
    }

    closeAll();

    // close all menu when click outside
    $(window).click(function() {
      closeAll();
    });

    container
      // stop propagation click outside in menu container
      .on("click", function(event) {
        event.stopPropagation();
      })
      // open a menu if is closed
      .on("click", ITEM_SEL + '[aria-expanded="false"]', function(event) {
        if($(this).attr('data-link') == undefined) {
            event.preventDefault();
            openMenu($(this), BIGITEM_SEL);
            $(CLOSE_SEL).attr("aria-hidden", "false");
        }
      })
      .on("click", MANUAL_MENU_ITEM_SEL, function(event) {
        event.preventDefault();
        openMenu($(this), MANUAL_MENU_BIGITEM_SEL, true);
      })
      // close all menu when click on an opened menu
      .on("click", ITEM_SEL + '[aria-expanded="true"]', function(event) {
        event.preventDefault();
        closeAll();
      })
      .on("click", MANUAL_MENU_ITEM_SEL + '[aria-expanded="true"]', function(event) {
        event.preventDefault();
        closeAll();
      })
      // close all menu when click on close button
      .on("click", MOBILE_CLOSE_SEL, function(event) {
        event.preventDefault();
        $(OVERLAY).css( "display", "none");
        $(DATABURGER_SEL).attr("aria-expanded", "false");
        $(CONTAINER_SEL).attr("data-burgernav", "close");
      })
      .on("click", DESKTOP_CLOSE_SEL, function(event) {
        event.preventDefault();
        closeAll();
      })
      .on("click", MANUAL_CLOSE_SEL, function(event) {
        event.preventDefault();
        closeAll();
      })
      .on("click", MOBILE_BACK_SEL, function(event) {
        event.preventDefault();
        closeAll();
      })
      .on("click", CLOSE_SEL, function(event) {
        event.preventDefault();
        closeAll();
      });
  }

  function closeAll() {
    $(ITEM_SEL).attr("aria-expanded","false");
    $(MANUAL_MENU_ITEM_SEL).attr("aria-expanded","false");
    $(BIGITEM_SEL).attr("aria-hidden", "true");
    $(MANUAL_MENU_BIGITEM_SEL).attr("aria-hidden", "true");
    $(ACTIVE_ITEM_SEL).attr("aria-hidden", "true");
    $(OVERLAY).css( "display", "none");
    $(CONTAINER_SEL).removeAttr("data-menu-open");
    $(CLOSE_SEL).attr("aria-hidden", "true");
  }

  function openMenu(el,panelSelector, manualMenu) {
    manualMenu = manualMenu || false;

    // Get active item menu block
    var panel
    var activeItem
    if (manualMenu) {
      panel = el.next().children(MANUAL_MENU_BIGITEM_SEL)
      activeItem = el.next(MANUAL_MENU).find(MANUAL_MENU_ACTIVE_ITEM_SEL)
    } else {
      panel = el.nextAll(panelSelector).first();
      activeItem = el.nextAll(ACTIVE_ITEM_SEL).first();
    }

    closeAll();
    
    el.attr("aria-expanded", "true");
    panel.attr("aria-hidden", "false");
    activeItem.attr("aria-hidden", "false");
    $(OVERLAY).css("display", "block");
    $(CONTAINER_SEL).attr("data-menu-open", "true");
  }

})(jQuery);
