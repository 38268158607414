/**
 * MenuBurger open main nav in full screen
 */
(function($) {
  "use strict";

  $(window).ready(init);

  // Constants
  var CONTAINER_SEL = "[data-burgermenu]";
  var NAV_SEL = ".MainNav";
  var CLOSE_SEL = ".MainNav-activeItem-close"

  /**
   * Init
   */
  function init() {

    var container = $(CONTAINER_SEL);
    if (container.length === 0) {
      return;
    }

    container.attr("aria-expanded", "false");
    $(NAV_SEL).attr("data-burgernav", "close");

    container.on('click', function(){
      var el = $(this);
      el.attr( "aria-expanded", el.attr("aria-expanded") == "true" ? "false" : "true");
      $(NAV_SEL).attr("data-burgernav", $(NAV_SEL).attr("data-burgernav") == "open" ? "close" : "open");
      $('body').css('overflow', el.attr("aria-expanded") == "true" ? 'hidden' : 'visible');
    })

    $(CLOSE_SEL).on("click", function(event) {
      event.preventDefault();
      $('body').css('overflow', $(CONTAINER_SEL).attr("aria-expanded") == "true" ? 'visible' : 'hidden');
    })

  }

})(jQuery);
